import React, { useEffect } from "react";
//redux logic
import { useDispatch, useSelector } from "react-redux";
import { loadGames, clearSearch } from "../actions/gamesAction";
//styling & animation
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useLocation } from "react-router-dom";
import { fadeIn } from "../animation";
//components
import Game from "../components/Game";
import GameDetail from "../components/GameDetail";
import clear from "../img/clear.svg";

const Home = () => {
  //get current location
  const location = useLocation();
  const pathID = location.pathname.split("/")[2];
  //init dispatch
  const dispatch = useDispatch();
  //fetch games & fill state
  useEffect(() => {
    dispatch(loadGames());
  }, [dispatch]);
  //get games state & destruct
  const { popular, newGames, upcoming, searched } = useSelector(
    (state) => state.games
  );

  const clearSearched = () => {
    dispatch(clearSearch());
  };
  return (
    <>
      <GameList variants={fadeIn} initial='hidden' animate='show' exit='exit'>
        <AnimateSharedLayout type='crossfade'>
          <AnimatePresence>
            {pathID && <GameDetail pathId={pathID} />}
          </AnimatePresence>
          {searched.length ? (
            <div className='searchedGames'>
              <SearchHeader>
                <h2>search result</h2>
                <img src={clear} alt='clear' onClick={clearSearched} />
              </SearchHeader>
              <Games>
                {searched.map((game) => (
                  <Game game={game} key={game.id} />
                ))}
              </Games>
            </div>
          ) : (
            ""
          )}
          <h2>upcoming games</h2>
          <Games>
            {upcoming.map((game) => (
              <Game game={game} key={game.id} />
            ))}
          </Games>
          <h2>popular games</h2>
          <Games>
            {popular.map((game) => (
              <Game game={game} key={game.id} />
            ))}
          </Games>
          <h2>new games</h2>
          <Games>
            {newGames.map((game) => (
              <Game game={game} key={game.id} />
            ))}
          </Games>
        </AnimateSharedLayout>
      </GameList>
      <Impressum>
        <p>
          <a href='https://loefflermartin.de' target='_blank'>
            martin loeffler
          </a>{" "}
          / 2021 / made with{" "}
          <a href='https://rawg.io/apidocs' target='_blank'>
            rawg api
          </a>{" "}
          /{" "}
          <a href='https://impressum.loefflermartin.de' target='_blank'>
            impressum
          </a>
        </p>
      </Impressum>
    </>
  );
};

const GameList = styled(motion.div)`
  padding: 0 5rem;
  min-height: 100vh;
  padding-bottom: 3rem;
  h2 {
    padding: 5rem 0;
  }
  @media (max-width: 630px) {
    padding: 0 1rem;
  }
`;

const Games = styled(motion.div)`
  min-height: 70vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;
`;

const SearchHeader = styled(motion.div)`
  display: flex;
  align-items: center;
  h2 {
    display: inline-block;
  }
  img {
    height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
  }
`;

const Impressum = styled(motion.div)`
  text-align: center;
  margin-bottom: 1rem;
`;

export default Home;
