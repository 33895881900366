import React from "react";
import { useHistory } from "react-router-dom";
import { smallImage } from "../util";
//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";
//redux
import { useSelector } from "react-redux";
//platform images
import playstation from "../img/playstation.svg";
import steam from "../img/steam.svg";
import xbox from "../img/xbox.svg";
import nintendo from "../img/nintendo.svg";
import apple from "../img/apple.svg";
import gamepad from "../img/gamepad.svg";
import starEmpty from "../img/star-empty.png";
import starFull from "../img/star-full.png";
import android from "../img/android.svg";

const GameDetail = ({ pathId }) => {
  //init history
  const history = useHistory();
  //get data from state
  const { game, screen, isLoading } = useSelector((state) => state.detail);

  //exit detail
  const exitDetailHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      history.push("/");
    }
  };
  //get logic
  const getStars = (ratingInput) => {
    const stars = [];
    const rating = Math.round(game.rating);
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<img alt='star' key={i} src={starFull}></img>);
      } else {
        stars.push(<img alt='star' key={i} src={starEmpty}></img>);
      }
    }
    return stars;
  };

  //platform logo function
  const getPlatform = (platformName) => {
    switch (platformName) {
      case "PlayStation 4":
        return playstation;
      case "PlayStation 5":
        return playstation;
      case "Xbox Series S/X":
        return xbox;
      case "Xbox S":
        return xbox;
      case "Xbox One":
        return xbox;
      case "PC":
        return steam;
      case "Nintendo Switch":
        return nintendo;
      case "iOS":
        return apple;
      case "Android":
        return android;
      default:
        return gamepad;
    }
  };

  return (
    <>
      {!isLoading && (
        <CardShadow className='shadow' onClick={exitDetailHandler}>
          <Detail layoutId={pathId}>
            <Stats>
              <div className='rating'>
                <motion.h3 layoutId={`title ${pathId}`}>{game.name}</motion.h3>
                {getStars()}
                <p>rating: {game.rating}</p>
              </div>
              <Info>
                <h3>platforms</h3>
                <Platform>
                  {game.platforms.map((el) => (
                    <div className='platformWrapper'>
                      <h3 className='platformName' key={el.platform.id}>
                        {el.platform.name}
                      </h3>
                      <img
                        src={getPlatform(el.platform.name)}
                        alt='platform icon'
                      />
                    </div>
                  ))}
                </Platform>
              </Info>
            </Stats>
            <Media>
              <motion.img
                src={smallImage(game.background_image, 1280)}
                alt='background'
                layoutId={`image ${pathId}`}
              />
            </Media>
            <Description>
              <p>{game.description_raw}</p>
            </Description>
            <MoreInfo>
              <h3>more infos</h3>

              {game.website && (
                <a href={game.website} target='_blank' className='websiteLink'>
                  Game-Website
                </a>
              )}
              {game.stores.length > 0 ? (
                <div className='stores'>
                  <h3>stores</h3>
                  <StoreWrapper>
                    {game.stores.map((el) => (
                      <StoreDetail el={el}>
                        <img
                          src={smallImage(el.store.image_background, 640)}
                          alt='store'
                        />
                        <p>{el.store.name}</p>
                        <a href={el.url} target='_blank'>
                          Link to game in store
                        </a>
                      </StoreDetail>
                    ))}
                  </StoreWrapper>
                </div>
              ) : null}
              {game.developers.length > 0 ? (
                <div className='stores'>
                  <h3>developers</h3>
                  <StoreWrapper>
                    {game.developers.map((el) => (
                      <StoreDetail el={el}>
                        <img
                          src={smallImage(el.image_background, 640)}
                          alt='store'
                        />
                        <p>{el.name}</p>
                      </StoreDetail>
                    ))}
                  </StoreWrapper>
                </div>
              ) : null}
              {game.genres.length > 0 ? (
                <div className='stores'>
                  <h3>genres</h3>
                  <StoreWrapper>
                    {game.genres.map((el) => (
                      <StoreDetail el={el}>
                        <img
                          src={smallImage(el.image_background, 640)}
                          alt='store'
                        />
                        <p>{el.name}</p>
                      </StoreDetail>
                    ))}
                  </StoreWrapper>
                </div>
              ) : null}
              {game.tags.length > 0 ? (
                <div className='stores'>
                  <h3>tags</h3>
                  <StoreWrapper>
                    {game.tags.map((el) => (
                      <StoreDetail el={el}>
                        <img
                          src={smallImage(el.image_background, 640)}
                          alt='store'
                        />
                        <p>{el.name}</p>
                      </StoreDetail>
                    ))}
                  </StoreWrapper>
                </div>
              ) : null}
              {game.publishers.length > 0 ? (
                <div className='stores'>
                  <h3>publishers</h3>
                  <StoreWrapper>
                    {game.publishers.map((el) => (
                      <StoreDetail el={el}>
                        <img
                          src={smallImage(el.image_background, 640)}
                          alt='store'
                        />
                        <p>{el.name}</p>
                      </StoreDetail>
                    ))}
                  </StoreWrapper>
                </div>
              ) : null}
            </MoreInfo>
            <div className='gallery'>
              {game.background_image_additional && (
                <img
                  src={smallImage(game.background_image_additional, 1280)}
                  alt='background_image_additional'
                />
              )}
              {screen.results.map((el) => (
                <img
                  src={smallImage(el.image, 1280)}
                  key={el.id}
                  alt='screenshot'
                  loading='lazy'
                />
              ))}
            </div>
            <Clip>
              {game.clip !== null ? (
                <div className='clipWrapper'>
                  <h3>clip</h3>
                  <video controls>
                    <source
                      src={game.clip.clips.full}
                      type='video/mp4'
                    ></source>
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : null}
            </Clip>
          </Detail>
        </CardShadow>
      )}
    </>
  );
};

const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const Detail = styled(motion.div)`
  width: 80%;
  border-radius: 1rem;
  padding: 2rem 5rem;
  background: white;
  position: absolute;
  left: 10%;
  top: 5%;
  color: black;
  z-index: 20;

  img {
    width: 100%;
  }
  .gallery {
    img {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 1200px) {
    padding: 2rem;
  }
`;

const Stats = styled(motion.div)`
  display: flex;
  align-items: top;
  justify-content: space-between;
  img {
    width: 1.3rem;
    height: rem;
    display: inline;
  }
  p {
    padding-left: 0;
    font-size: 1rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Info = styled(motion.div)`
  text-align: right;
  h3 {
    padding-bottom: 0;
  }
`;

const Platform = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  img {
    margin-left: 3rem;
  }
  .platformName {
    padding: 0.5rem;
    font-weight: lighter;
    font-size: 1rem;
    width: 100%;
    white-space: nowrap;
  }
  .platformWrapper {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: top;
    height: 1.5rem;
    margin: 0.1rem 0;
    img {
      height: 100%;
      width: 20px;
      margin: 0;
      padding: 0;
    }
  }
`;

const Media = styled(motion.div)`
  margin-top: 5rem;
  img {
    width: 100%;
  }
`;

const Description = styled(motion.div)`
  margin: 5rem 0;
`;

const MoreInfo = styled(motion.div)`
  h3 {
    padding-top: 0;
  }
  .stores {
    h3 {
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }
`;

const StoreWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin: 2rem 0;
`;
const StoreDetail = styled(motion.div)`
  position: relative;
  min-height: 20rem;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 20rem;
    height: 20rem;
    object-fit: cover;
  }
  p {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 20rem;
    text-align: center;
    color: white;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.7);
  }
  a {
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: 20rem;
    text-align: center;
    color: white;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.7);
  }
`;

const Clip = styled(motion.div)`
  width: 100%;
  video {
    width: 100%;
  }
`;
export default GameDetail;
