import React from "react";
import { Link } from "react-router-dom";
import { smallImage } from "../util";
//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";
import { popup } from "../animation";

//redux
import { useDispatch } from "react-redux";
import { loadDetail } from "../actions/detailAction";

const Game = ({ game }) => {
  //destruct game
  const { name, released, background_image, id } = game;
  const stringPathId = id.toString();

  //load details handler
  const dispatch = useDispatch();
  const loadDetailHandler = () => {
    document.body.style.overflow = "hidden";
    dispatch(loadDetail(id));
  };

  return (
    <StyledGame
      layoutId={stringPathId}
      onClick={loadDetailHandler}
      variants={popup}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <Link to={`/game/${id}`}>
        <motion.h3 layoutId={`title ${stringPathId}`}>{name}</motion.h3>
        <p>release date: {released}</p>
        {background_image && (
          <motion.img
            layoutId={`image ${stringPathId}`}
            src={smallImage(background_image, 640)}
            alt={name}
          />
        )}
      </Link>
    </StyledGame>
  );
};

const StyledGame = styled(motion.div)`
  min-height: 30vh;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
`;

export default Game;
