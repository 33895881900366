import React from "react";
//pages & components
import Home from "./pages/Home";
import Nav from "./components/Nav";
//global style
import GlobalStyle from "./components/GlobalStyles";
//react router //array = on both routes render out Home
import { Route } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <GlobalStyle />
      <Nav />
      <Route path={["/game/:id", "/"]}>
        <Home />
      </Route>
    </div>
  );
}

export default App;
